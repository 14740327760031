/* image {
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: -1;
} */

.hero-container {
  background: url(/src/images/Mountain.jpeg) center center/cover no-repeat;
  background-color: rgba(0, 0, 0, 0.3);
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: center;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
  object-fit: contain;
}

.hero-container > h1 {
  color: #f3f3f3;
  font-size: 100px;
  margin-top: 10px;
  margin-left: 40px;
  padding: 0 25px;
  background-color: rgba(0, 0, 0, 0.0);
}

.hero-container > p {
  margin-top: 20px;
  margin-left: 40px;
  padding: 0 25px;
  color: #f3f3f3;
  background-color: rgba(0, 0, 0, 0.0);
  font-size: 32px;
}

.hero-btns {
  margin-top: 20px;
  margin-left: 18px;
  padding: 0 25px;
  color: #fff;
}

@media screen and (max-width: 960px) {

  .hero-container > h1 {
    color: #fff;
    font-size: 70px;
    margin-top: 80px;
    margin-left: 40px;
    padding: 0 25px;
    background-color: rgba(0, 0, 0, 0.7);
  }
}

@media screen and (max-width: 768px) {

  .hero-container > h1 {
    display: flex;
    color: #fff;
    font-size: 50px;
    margin-top: 80px;
    margin-left: 40px;
    padding: 0 25px;
    background-color: rgba(0, 0, 0, 0.7);
  }

  .hero-container > p {
    margin-top: 20px;
    margin-left: 40px;
    padding: 0 25px;
    color: #fff;
    background-color: rgba(0, 0, 0, 0.7);
    font-size: 30px;
  }
}
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "PT Sans", sans-serif;
}

.AboutMe-Container {
  display: flex;
  align-items: center;
  padding: 20px;
  margin: 100px;
}

.Image-Container {
  display: flex;
  width: 100%;
  align-items: center;
  /* padding: 50px; */
}

.Content-Container {
  display: flex;
  flex-direction: column;
  align-self: center;
  margin-right: 350px;
}

.portrait {
  display: flex;
  align-items: center;
  margin-left: 300px;
  margin-right: 25px;
  width: 100%;
}

.BlogVlog-Title {
  padding: 50px;
}

.Experience-Title {
  padding: 50px;
}

.Portfolio-Container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Portfolio {
  padding: 50px;
}

.Resume {
  display: flex;
  align-items: center;
  width: 65%;
}

@media only screen and (min-width: 1000px) {
  .portrait {
    height: 550px;
    width: 500px;
  }
}
@media only screen and (max-width: 1500px) {
  .AboutMe-Container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0;
    margin: 0;
    width: 100%;
  }

  .Image-Container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 20px;
  }

  .portrait {
    height: 350px;
    width: 250px;
    margin-left: 25px;
  }

  .Content-Container {
    display: flex;
    align-items: center;
    margin: 20px 100px;
  }
}
